import { Job } from '@billytics/models';

export class JobsGet {
  static readonly type = '@JobsState.get';
}

export class JobsCreate {
  static readonly type = '@JobsState.create';
  constructor(public payload: Pick<Job, 'type' | 'jobPayload'>) {}
}
